@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-mv {
  position: relative;
  overflow: hidden; }
  #top .p-mv__inner {
    width: 100%;
    position: relative; }
    #top .p-mv__inner::before {
      content: '';
      background-color: #000000;
      opacity: .3;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none; }
  #top .p-mv__video {
    width: 100%;
    height: calc(100vh - 84px);
    display: block;
    line-height: 1;
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover; object-position: center;"; }
    @media screen and (max-width: 1024px) {
      #top .p-mv__video {
        height: calc(100vh - 60px); } }
  #top .p-mv__txt {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none; }
    #top .p-mv__txt img {
      display: block;
      width: 100%;
      line-height: 1; }
      @media screen and (max-width: 1200px) {
        #top .p-mv__txt img {
          height: 250px;
          object-fit: cover;
          object-position: center;
          font-family: "object-fit: cover; object-position: center;"; } }
      @media screen and (max-width: 568px) {
        #top .p-mv__txt img {
          height: 230px; } }
    @media screen and (max-width: 1280px) {
      .eng #top .p-mv__txt img {
        height: 120px; } }
    @media screen and (max-width: 568px) {
      .eng #top .p-mv__txt img {
        height: 75px; } }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1200px) {
    #top #wrapper {
      padding: 60px 0 0; } }

#top .p-feature__content {
  max-width: 560px; }
  @media screen and (max-width: 768px) {
    #top .p-feature__content {
      max-width: 100%; } }

#top .p-feature__img {
  max-width: 444px;
  margin-left: auto; }
  @media screen and (max-width: 768px) {
    #top .p-feature__img {
      margin: 0 auto; } }
  @media screen and (max-width: 568px) {
    #top .p-feature__img {
      padding: 0 15px; } }

#top .p-works__head {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0; }
  @media screen and (max-width: 768px) {
    #top .p-works__head {
      flex-direction: column; } }
  #top .p-works__head p {
    flex-grow: 1; }

#top .p-works__heading {
  margin-bottom: 0;
  flex: 0 0 250px; }
  @media screen and (max-width: 1024px) {
    #top .p-works__heading {
      flex: 0 0 180px; } }
  @media screen and (max-width: 768px) {
    #top .p-works__heading {
      margin-bottom: 10px;
      flex: 1 1 100%; } }

#top .p-works__list {
  display: flex;
  flex-wrap: wrap; }

#top .p-works__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: all 0.2s ease;
  transform: scale(1.01); }
  #top .p-works__bg img {
    display: block;
    line-height: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover; object-position: center;"; }

#top .p-works__item {
  width: 25%;
  height: 256px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    #top .p-works__item {
      height: 180px; } }
  @media screen and (max-width: 768px) {
    #top .p-works__item {
      width: 50%; } }
  @media screen and (max-width: 568px) {
    #top .p-works__item {
      height: 120px; } }
  #top .p-works__item::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: #000000;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none; }
  #top .p-works__item > a:not(.c-btn) {
    position: relative;
    z-index: 2;
    color: #FFFFFF;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: .08em;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.46);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px; }
    @media screen and (max-width: 568px) {
      #top .p-works__item > a:not(.c-btn) {
        font-size: 18px; } }
    #top .p-works__item > a:not(.c-btn):hover + .p-works__bg {
      transform: scale(1.2); }
    .eng #top .p-works__item > a:not(.c-btn) {
      text-align: center;
      letter-spacing: normal; }
      @media screen and (max-width: 1024px) {
        .eng #top .p-works__item > a:not(.c-btn) {
          font-size: 22px; } }
      @media screen and (max-width: 568px) {
        .eng #top .p-works__item > a:not(.c-btn) {
          font-size: 18px; } }
  #top .p-works__item--all {
    padding: 20px; }
    @media screen and (max-width: 1024px) {
      #top .p-works__item--all {
        padding: 10px; } }
    #top .p-works__item--all::before {
      content: none; }
    #top .p-works__item--all .c-btn {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600; }
      @media screen and (max-width: 568px) {
        #top .p-works__item--all .c-btn {
          font-size: 12px; } }
      #top .p-works__item--all .c-btn > span::before {
        content: "\f022";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        font-size: 36px;
        font-weight: 400;
        display: block;
        text-align: center; }
        @media screen and (max-width: 568px) {
          #top .p-works__item--all .c-btn > span::before {
            font-size: 26px; } }

#top .p-works .l-section {
  padding-bottom: 0; }

@media screen and (max-width: 768px) {
  #top .p-company__list {
    margin-top: 20px; } }

#top .p-company__list-item {
  margin-bottom: 25px; }
  @media screen and (max-width: 768px) {
    #top .p-company__list-item {
      margin-bottom: 15px; } }
  #top .p-company__list-item:last-child {
    margin-bottom: 0; }

#top .p-group__wrap {
  margin-bottom: 30px; }

#top .p-group__box {
  margin-bottom: 40px; }
  #top .p-group__box:last-child {
    margin-bottom: 0; }

#top .p-group__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -10px; }
  @media screen and (max-width: 768px) {
    #top .p-group__list {
      justify-content: flex-start; } }
  @media screen and (max-width: 568px) {
    #top .p-group__list {
      flex-direction: column; } }
  #top .p-group__list-item {
    max-width: 25%;
    width: 100%;
    padding: 0 10px 20px; }
    @media screen and (max-width: 768px) {
      #top .p-group__list-item {
        max-width: 50%; } }
    @media screen and (max-width: 568px) {
      #top .p-group__list-item {
        max-width: 300px;
        justify-content: center; } }
    #top .p-group__list-item > a {
      display: block;
      transition: all 0.2s ease; }
      #top .p-group__list-item > a::after {
        content: none; }
      #top .p-group__list-item > a:hover {
        opacity: .7; }
    #top .p-group__list-item img {
      display: block;
      line-height: 1;
      width: 100%; }

#top .p-news__wrap {
  display: flex; }
  @media screen and (max-width: 834px) {
    #top .p-news__wrap {
      flex-direction: column; } }

#top .p-news__head {
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 40px;
  position: relative; }
  @media screen and (max-width: 834px) {
    #top .p-news__head {
      flex: 1 1 100%;
      padding-right: 0; } }

#top .p-news__body {
  max-width: 870px;
  width: 100%;
  flex-shrink: 1; }
  @media screen and (max-width: 834px) {
    #top .p-news__body {
      flex: 1 1 100%; } }

#top .p-news__heading {
  margin-bottom: 24px; }

@media screen and (max-width: 834px) {
  #top .p-news__link {
    position: absolute;
    bottom: 24px;
    right: 0; } }

#top .p-schedule {
  max-width: 930px;
  margin: 0 auto;
  padding-bottom: 120px; }
  @media screen and (max-width: 1200px) {
    #top .p-schedule {
      padding-left: 15px;
      padding-right: 15px; } }
  #top .p-schedule > a {
    display: block;
    line-height: 1;
    width: 100%;
    position: relative;
    overflow: hidden; }
    #top .p-schedule > a::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: .5;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #top .p-schedule > a:hover .p-schedule__bg {
      transform: scale(1.1); }
  #top .p-schedule__bg {
    transition: all 0.2s ease; }
    #top .p-schedule__bg img {
      display: block;
      line-height: 1;
      width: 100%; }
  #top .p-schedule__txt {
    color: #FFFFFF;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    z-index: 2; }
    @media screen and (max-width: 568px) {
      #top .p-schedule__txt {
        font-size: 16px; } }
    #top .p-schedule__txt > small {
      display: block;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 3px; }
      @media screen and (max-width: 568px) {
        #top .p-schedule__txt > small {
          font-size: 14px; } }
    #top .p-schedule__txt::before {
      content: "\f073";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: block;
      line-height: 1;
      font-size: 40px;
      font-weight: 100;
      margin-bottom: 8px; }
      @media screen and (max-width: 568px) {
        #top .p-schedule__txt::before {
          font-size: 28px; } }

#top .p-recruit {
  padding-top: 0; }
  #top .p-recruit__bg {
    position: relative; }
    #top .p-recruit__bg::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: .5;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #top .p-recruit__bg img {
      display: block;
      line-height: 1;
      width: 100%;
      transition: all 0.2s ease; }
      @media screen and (max-width: 768px) {
        #top .p-recruit__bg img {
          height: 450px;
          object-fit: cover;
          object-position: center; } }
  #top .p-recruit__area {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    overflow: hidden; }
    #top .p-recruit__area-inner {
      max-width: 700px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; }
      @media screen and (max-width: 768px) {
        #top .p-recruit__area-inner {
          padding-left: 15px;
          padding-right: 15px; } }
    #top .p-recruit__area-txt {
      color: #FFFFFF;
      margin-bottom: 0; }
    #top .p-recruit__area-more {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      text-align: center; }
      #top .p-recruit__area-more > span {
        display: inline-block;
        position: relative;
        padding-right: 13px; }
        #top .p-recruit__area-more > span::after {
          content: "\f105";
          font-family: "Font Awesome 5 Free";
          font-weight: 600;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: all 0.2s ease; }
    #top .p-recruit__area:hover .p-recruit__bg img {
      transform: scale(1.1); }
    #top .p-recruit__area:hover .p-recruit__area-more > span::after {
      right: -5px; }
